import React from 'react';
import icon1 from "../assets/x-social-media-white-square-icon.webp";
import icon2 from "../assets/telesocialmedia.webp";

const Footer = () => {
  return (
    <>
      <footer className="fixed bottom-0 left-0 right-0 pb-20 sm:pb-4 p-4 flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between items-center w-full text-white">
        <div className="font-custom text-[20px] text-center sm:text-[30px] font-semibold">
          SPECIAL <span className="text-firmament-green">FIRMAMENT NFT</span> COMING FOR ALL HOLDERS!
        </div>
        <div className="flex justify-end items-center">
          <a href="/" className="hover:scale-110 transition-transform duration-300">
            <img src={icon1} alt="Icon 1" className="w-10 h-10 sm:w-20 sm:h-20 mr-4" />
          </a>
          <a href="https://t.me/flaterf" className="hover:scale-110 transition-transform duration-300">
            <img src={icon2} alt="Icon 2" className="w-10 h-10 sm:w-20 sm:h-20" />
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;
