import React from 'react';

const Header = () => {
  const caAddress = "0x297fFB5b3310E36C36d1b4c08A14C6c3D93346cB";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(caAddress);
    alert("CA address copied to clipboard!");
  };

  return (
    <header className="fixed z-50 flex flex-col sm:flex-row w-full sm:justify-between sm:px-10 pt-20 sm:pt-4 items-center font-custom">
      <div className="w-2/7 text-header-text-color text-[30px] sm:text-[35px]">
        $FLAT
      </div>
      <div className="w-5/7 text-header-text-color text-[16px] sm:text-[25px] font-custom">
        CA: &nbsp;
        <span
          className="cursor-pointer"
          style={{ userSelect: 'text' }}
          onClick={copyToClipboard}
        >
          {caAddress}
        </span>
      </div>
    </header>
  );
};

export default Header;
