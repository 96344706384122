import backgroundImage from "../assets/bg.jpg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import centerImage from "../assets/flatearth.png";
import astronautImage from "../assets/naut.png";

const Home = () => {
 return (
    <>
        <main style={{ backgroundImage: `url(${backgroundImage})`}}  className="bg-cover bg-center w-full bg-black overflow-hidden">
            <Header/>
            <div className="relative w-full flex justify-center items-center h-screen">
               <img src={centerImage} alt="main content"className="w-9/12 sm:w-7/12"/>
               <img src={astronautImage} alt="main content"className="robot absolute w-nat left-[150px] top-[50vh] -rotate-12" />               
            </div>
            <Footer/>
        </main>
    </>
 );
}

export default Home;
